'use client'

import { useUser } from '@clerk/nextjs'
import * as Avatar from '@radix-ui/react-avatar'
import { User } from '@phosphor-icons/react'
import Image from 'next/image'
import { useState } from 'react'

const UserAvatar = () => {
	const {
		user,
	} = useUser()

	const [
		error,
		setError,
	] = useState<boolean>(false)

	return (
		<Avatar.Root className="relative inline-flex size-[45px] select-none items-center justify-center overflow-hidden rounded-full align-middle">
			{user && !error ? (
				<div className="relative size-full">
					<Image
						onError={() => {
							setError(true)
						}}
						className="size-full rounded-full object-cover"
						fill={true}
						src={user.imageUrl}
						alt={[
							user.firstName,
							user.lastName,
							'avatar',
						]
							.filter(Boolean)
							.join(' ')}
					/>
				</div>
			) : (
				<Avatar.Fallback
					className="flex size-full items-center justify-center bg-white text-[15px] font-medium leading-none"
					delayMs={600}
				>
					<User />
				</Avatar.Fallback>
			)}
		</Avatar.Root>
	)
}

export default UserAvatar
