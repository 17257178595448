'use client'

import { Button, TextArea } from '@radix-ui/themes'
import { parseWithZod } from '@conform-to/zod'
import { z } from 'zod'
import { useRef, useActionState } from 'react'
import { useForm } from '@conform-to/react'
import { startConversation } from '../actions'
import { CircleNotch } from '@phosphor-icons/react'

const SearchForm = ({
	placeholder,
}: Readonly<{
	placeholder: string
}>) => {
	const [
		lastResult,
		action,
		isPending,
	] = useActionState(startConversation, undefined)

	const [
		form,
		fields,
	] = useForm({
		lastResult,
		onValidate: ({
			formData,
		}) => {
			return parseWithZod(formData, {
				schema: z.object({
					query: z.string(),
				}),
			})
		},
	})

	const submitRef = useRef<HTMLButtonElement>(null)

	return (
		<div className="peer flex rounded-xl border border-neutral-200 bg-neutral-100 p-sm has-[:focus]:border-neutral-300">
			<form
				id={form.id}
				className="flex w-full flex-col items-end"
				action={action}
				onSubmit={form.onSubmit}
				noValidate={true}
			>
				<TextArea
					id={fields.query.id}
					name={fields.query.name}
					placeholder={placeholder}
					variant="soft"
					className="w-full bg-transparent text-lg outline-none"
					resize="none"
					size="3"
					autoFocus={true}
					spellCheck={false}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							if (event.shiftKey) {
								event.preventDefault()
							}

							if (!event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
								event.preventDefault()
								submitRef.current?.click()
							}
						}
					}}
					disabled={isPending}
				/>
				<Button
					className="w-fit"
					type="submit"
					ref={submitRef}
					disabled={isPending}
					// loading={isPending}
				>
					{isPending ? (
						<CircleNotch
							size={16}
							className="animate-spin"
						/>
					) : null}
					{'Search'}
				</Button>
			</form>
		</div>
	)
}

export default SearchForm
