'use client'

import * as Sentry from '@sentry/nextjs'
import type Error from 'next/error'
import { useEffect } from 'react'

const GlobalError = ({
	error,
}: Readonly<{ error: Error }>) => {
	useEffect(() => {
		Sentry.captureException(error)
	}, [
		error,
	])

	return (
		<html>
			<body>
				{'Something went wrong'}
			</body>
		</html>
	)
}

export default GlobalError
