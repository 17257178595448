'use client'

import {
	type ReactNode,
	useMemo,
	useEffect,
	type ComponentType,
} from 'react'

import type {
	ToolInvocation,
	Message,
	LanguageModelUsage,
	FinishReason,
	CoreToolCall,
} from 'ai'
import { useChat } from 'ai/react'
import { createRequiredContext } from '~/utils/create-required-context'
import { publicConfig } from '~/config'

// eslint-disable-next-line no-restricted-syntax
export type Tool = {
	name: string
	render?: ComponentType<{
		toolInvocation: ToolInvocation
		addToolResult: ReturnType<typeof useChat>['addToolResult']
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
		getToolResult: <T,>() => T | null
		messages: Message[]
	}>
	onCall?: (toolCall: CoreToolCall<string, unknown>) => (Promise<void> | void)
	onResult?: (toolInvocation: ToolInvocation, options: {
		usage: LanguageModelUsage
		finishReason: FinishReason
	}) => void
}

type UniqueArray<T extends readonly unknown[], Key extends keyof T[number]> =
	T extends [infer First, ...infer Rest]
		? First[Key] extends Rest[number][Key]
			? never // Duplicate found, reject the type
			: UniqueArray<Rest, Key>
		: T

// eslint-disable-next-line no-restricted-syntax
export type ToolsList = UniqueArray<Tool[], 'name'>

type ConversationState = {
	tools: Record<string, {
		render?: ComponentType<{
			toolInvocation: ToolInvocation
			addToolResult: ReturnType<typeof useChat>['addToolResult']
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
			getToolResult: <T,>() => T | null
			messages: Message[]
		}>
		onCall?: (toolCall: CoreToolCall<string, unknown>) => (Promise<void> | void)
		onResult?: (toolInvocation: ToolInvocation, options: {
			usage: LanguageModelUsage
			finishReason: FinishReason
		}) => void
	}>
} & ReturnType<typeof useChat>

const [
	Provider,
	useContext,
] = createRequiredContext<ConversationState>()

export const ConversationProvider = ({
	api,
	conversationId,
	initialMessages,
	children,
	tools,
}: Readonly<{
	api: string
	conversationId: number
	initialMessages: Message[]
	children: ReactNode
	tools?: ToolsList
}>) => {
	const memoizedTools = useMemo(() => {
		return tools?.reduce<Record<string, Omit<typeof tools[number], 'name'>>>((acc, tool) => {
			acc[tool.name] = {
				render: tool.render,
				onCall: tool.onCall,
				onResult: tool.onResult,
			}

			return acc
		}, {}) ?? {}
	}, [
		tools,
	])

	const {
		reload,
		messages,
		isLoading,
		stop,
		...otherOptions
	} = useChat({
		id: String(conversationId),
		// sendExtraMessageFields: true,
		initialMessages,
		body: {
			conversationId,
		},
		sendExtraMessageFields: true,
		api,
		maxSteps: 3,
		onFinish: (message, options) => {
			console.log('message', message)

			if (Array.isArray(message.toolInvocations)) {
				message.toolInvocations.forEach((toolInvocation: ToolInvocation) => {
					if (toolInvocation.toolName in memoizedTools) {
						const tool = memoizedTools[toolInvocation.toolName]
						tool.onResult?.(toolInvocation, options)
					}
				})
			}
		},
		onToolCall: async ({
			toolCall,
		}) => {
			console.log('toolCall', toolCall)

			if (toolCall.toolName in memoizedTools) {
				const tool = memoizedTools[toolCall.toolName]
				await tool.onCall?.(toolCall)
			} else if (publicConfig.app.isDevelopment) {
				console.warn(`Tool call ${toolCall.toolName} with id ${toolCall.toolCallId} not found.`)
			} else {
				console.error(`Tool ${toolCall.toolName} not recognized.`)
			}
		},
		fetch,
	})

	useEffect(() => {
		if (initialMessages.at(-1)?.role === 'user') {
			void reload()

			return () => {
				stop()
			}
		}
	}, [])

	return (
		<Provider
			value={{
				...otherOptions,
				isLoading,
				reload,
				messages,
				stop,
				tools: memoizedTools,
			}}
		>
			{children}
		</Provider>
	)
}

export const useConversationContext = () => {
	return useContext()
}
