import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dunbar-core/dunbar-core/node_modules/.pnpm/next@15.1.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_babel-plugin-react-compiler@19.0.0-be_utgeaiy4ibzjf5goqdzks2usl4/node_modules/next/dist/lib/metadata/metadata-boundary.js");
