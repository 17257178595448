'use client'

import { KnockProvider } from '@knocklabs/react'

import { publicConfig } from '~/config'

import { client } from '~/trpc/client'
import type { ReactNode } from 'react'

const KnockNotificationsProvider = ({
	children,
}: Readonly<{
	children: ReactNode
}>) => {
	const {
		data,
	} = client.authCheck.useQuery()

	return (
		<KnockProvider
			apiKey={publicConfig.knock.publicKey}
			userId={data?.customerId.toString() ?? undefined}
		>
			{children}
		</KnockProvider>
	)
}

export default KnockNotificationsProvider
